<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard color="primary">
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left  text-white">List Activies</h4> 
            <!-- <a href="/#/coins/add" class="float-right btn btn-warning form-control-sm">Add New Coin</a> -->

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table class="table table-dark" id="datatable">
          <thead>
            <tr>
              <th class="text-white" style="width:30px">#</th>
              <th class="text-white">Note</th>
              <th class="text-white">User </th>
              <th  class="text-white" style="width:130px" >Date </th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
  </div>
</template>
<style scoped>
div{
  color:#fff
}
</style>
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
// import axios from 'axios';
import $ from 'jquery'; 

export default {
  filters: {
    rupiah: function(value) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadData: function() {
      if (this.tahun == null){
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/activities/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "note_activity" },
                  { "data": "member_name", className: "text-center" },
                  { "data": "pdate", className: "text-center" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/activities/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "note_activity" },
                  { "data": "member_name", className: "text-center" },
                  { "data": "pdate", className: "text-center" },
              ] 
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>